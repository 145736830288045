import * as React from "react"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2 } from "../../shared/headline.styles"
import { ImageWithBlur } from "../../shared/"
import { AppStateContext } from "../../../utils/PageWrapper"

interface DesignedSectionProps {
    title: string | JSX.Element
    lead: string
    image: string
}

export const DesignedSection: React.FC<DesignedSectionProps> = ({ title, lead, image }) => {
    const appState = React.useContext(AppStateContext)
    return (
        <Row>
            <Col lg={5} md={10} sm={12}>
                <_h2 align="left">{title}</_h2>

                {appState.isMobile && <ImageWithBlur image={image} />}
                <_Blockquote>{lead}</_Blockquote>
            </Col>

            {!appState.isMobile && (
                <Col lg={6} lgOffset={1} md={10} sm={12}>
                    <ImageWithBlur image={image} />
                </Col>
            )}
        </Row>
    )
}
