import * as React from "react"
import { Layout, _Section, HeadComponent } from "../../components/shared"
import { graphql } from "gatsby"
import { Hero } from "../../components/productsPage/shared"
import { TestimonialSection, DesignedSection } from "../../components/productsPage/Dialogue"
import { prismicGetText, extractPrismicNode, prismicGetRichHeader } from "../../utils/prismicHelper"
import { PageWrapper } from "../../utils/PageWrapper"
import { TestimonialItemProps } from "../../components/shared/Testimonials"

export const prismicQuery = graphql`
    {
        prismic {
            ...dialogueFragment
        }
    }
`

interface DialoguePageProps {
    data: {
        prismic: {
            allProduct_dialogues: {
                edges: any
            }
        }
    }
}

interface ItemProps {
    dialogue_testimonial: any
    testimonial_position: string
    testimonial_name: string
    testimonial_avatar: string
}

const normalize = (testimonials: ItemProps[]): TestimonialItemProps[] => {
    const normalizedTestimonials: TestimonialItemProps[] = []
    testimonials.forEach(element => {
        normalizedTestimonials.push({
            testimonial: element.dialogue_testimonial,
            name: element.testimonial_name,
            position: element.testimonial_position,
            avatar: element.testimonial_avatar
        })
    })

    return normalizedTestimonials
}

const DialoguePage: React.FC<DialoguePageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allProduct_dialogues")
    if (!doc) return null

    return (
        <>
            <HeadComponent
                title="Dialogue | Radicle"
                keywords={prismicGetText(doc.seo_keywords_dialogue)}
                description={prismicGetText(doc.seo_description_dialogue)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Dialogue"
                            title={prismicGetRichHeader(doc.dialogue_title_v2)}
                            lead={prismicGetText(doc.dialogue_lead)}
                            buttonLabel={prismicGetText(doc.cta_label_dialogue)}
                            image={doc.dialogue_hero_image.url}
                        />
                    </_Section>

                    <_Section>
                        <TestimonialSection
                            title={prismicGetRichHeader(doc.e2e_title_v2)}
                            lead={prismicGetText(doc.e2e_lead)}
                            normalizedTestimonials={normalize(doc.body[0].fields)}
                        />
                    </_Section>

                    <_Section>
                        <DesignedSection
                            title={prismicGetRichHeader(doc.designed_title_v2)}
                            lead={prismicGetText(doc.designed_lead)}
                            image={doc.designed_image.url}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default DialoguePage
