import * as React from "react"

import { AppStateContext } from "../../../utils/PageWrapper"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2LineThrough } from "../../shared/headline.styles"
import { Testimonials, _Section } from "../../shared"
import { TestimonialItemProps } from "../../shared/Testimonials"
import { FullWidthSection } from "../../shared"

interface TestimonialSectionProps {
    title: string | JSX.Element
    lead: string | JSX.Element
    normalizedTestimonials: TestimonialItemProps[]
    displayBottomImage?: boolean
}

export const TestimonialSection: React.FC<TestimonialSectionProps> = ({
    title,
    lead,
    normalizedTestimonials,
    displayBottomImage = true
}) => {
    const appState = React.useContext(AppStateContext)

    return (
        <FullWidthSection displayBottomImage={displayBottomImage}>
            <>
                <_Section marginBottom={"0"} marginTop={"0"}>
                    <Row>
                        <Col lg={6} md={10} sm={12}>
                            <_h2LineThrough fontSizeSm={36} align="left" style={{ textDecorationColor: "#ff8155" }}>
                                {title}
                            </_h2LineThrough>
                            <_Blockquote>{lead}</_Blockquote>
                        </Col>
                    </Row>
                </_Section>

                {normalizedTestimonials && (
                    <_Section marginTop={"0"} marginBottom={"0"}>
                        <Testimonials testimonialFields={normalizedTestimonials} isMobile={appState.isMobile} />
                    </_Section>
                )}
            </>
        </FullWidthSection>
    )
}
